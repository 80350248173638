// src/components/Auth/EmailVerificationMessage.js
import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

const EmailVerificationMessage = () => {
    const navigate = useNavigate();

    const handleRedirectToLogin = () => {
        navigate('/login');
    };

    return (
        <Card>
            <div className="verification-container" style={{ maxWidth: '400px', margin: '0 auto', padding: '2rem' }}>
                <h2>Verifique seu E-mail</h2>
                <p>
                    Um e-mail de verificação foi enviado para você. Por favor, verifique sua caixa de entrada e siga as instruções para ativar sua conta.
                </p>
                <p>
                    Após verificar seu e-mail, você poderá fazer login em sua conta.
                </p>
                <Button label="Voltar ao Login" onClick={handleRedirectToLogin} className="p-mt-2" />
            </div>
        </Card>
    );
};

export default EmailVerificationMessage;
