import React from 'react';
import { Menubar } from 'primereact/menubar'; 
import Banner from "./Banner"; 
import { Button } from "primereact/button"; 

const Header = ({ onLogout, isAuthenticated, emailVerified }) => {
  const items = [
    {
      label: 'Início',
      icon: 'pi pi-fw pi-home',
      command: () => { window.location.href = '/'; }
    },
    {
      label: 'Login',
      icon: 'pi pi-sign-in',
      command: () => {
          window.location.href = '/login'; 
      },
      visible: !isAuthenticated // Mostrar apenas se não estiver autenticado
    },
    {
      label: 'Registrar',
      icon: 'pi pi-user-plus',
      command: () => {
          window.location.href = '/register'; 
      },
      visible: !isAuthenticated // Mostrar apenas se não estiver autenticado
    },
    {
      label: 'Cardápios',
      icon: 'pi pi-address-book',
      command: () => {
        window.location.href = '/menu'; 
      },
      visible: isAuthenticated && emailVerified 
    },
    {
      label: 'Endereços',
      icon: 'pi pi-cog',
      command: () => {
        window.location.href = '/establishment'; 
      },
      visible: isAuthenticated && emailVerified 
    },
    {
      label: 'Design',
      icon: 'pi pi-palette',
      command: () => {
        window.location.href = '/design'; 
      },
      visible: isAuthenticated && emailVerified 
    },
  ];

  // Item de Logout separado para ser colocado no final
  const end = (
    isAuthenticated && emailVerified && (
      <Button 
          severity="secondary" 
          className="p-button-rounded" 
          onClick={onLogout}
          width="100px"
      >
          <i className="pi pi-sign-out" style={{ marginRight: '0.5rem'}}></i> Sair
      </Button>

    )
  );

  return (
    <header>
      <Banner />
      <Menubar model={items} end={end} />
    </header>
  );
};

export default Header;
