import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Importe as imagens dos ícones
import homeIcon from "../../images/home.png";
import menuIcon from "../../images/menu.png";
import addressIcon from "../../images/address.png";
import designIcon from "../../images/design.png";

// Função auxiliar para aplicar a cor correta
const getIconStyle = (isActive) => ({
    filter: isActive ? "none" : "grayscale(100%)", // Aplica escala de cinza se não estiver ativo
    opacity: isActive ? 1 : 0.5, // Opacidade reduzida se não estiver ativo
    transition: "transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease",
    boxShadow: isActive ? "0px 4px 15px rgba(0, 0, 0, 0.3)" : "none",
    transform: isActive ? "scale(1.3)" : "scale(1)",
});

const NavigationIcons = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Função para verificar se um ícone está ativo
    const isActive = (path) => location.pathname === path;

    return (
        <div style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            padding: "0.5rem 0", // Ajuste a margem superior e inferior
            background: "linear-gradient(135deg, #f0f4f8, #d9e2ec)",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        }}>
            {/* Ícone Home */}
            <div style={{ 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                padding: isActive("/") ? "5px" : "0",
                background: isActive("/") ? "#e0f7fa" : "transparent",
                borderRadius: "8px",
                transition: "background 0.3s ease", 
            }}>
                <img 
                    src={homeIcon} 
                    alt="Início" 
                    onClick={() => navigate("/")} 
                    style={{
                        cursor: "pointer",
                        width: "50px",
                        height: "50px",
                        filter: getIconStyle(isActive("/")).filter,
                        opacity: getIconStyle(isActive("/")).opacity,
                        boxShadow: getIconStyle(isActive("/")).boxShadow,
                        transform: getIconStyle(isActive("/")).transform,
                    }}
                />
                <span style={{ marginTop: "8px", fontSize: "14px", color: "#333", fontWeight: isActive("/") ? "bold" : "normal" }}>
                    Início
                </span>
            </div>

            {/* Ícone Menu */}
            <div style={{ 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                padding: isActive("/menu") ? "5px" : "0",
                background: isActive("/menu") ? "#e0f7fa" : "transparent",
                borderRadius: "8px",
                transition: "background 0.3s ease", 
            }}>
                <img 
                    src={menuIcon} 
                    alt="Menu" 
                    onClick={() => navigate("/menu")} 
                    style={{
                        cursor: "pointer", 
                        width: "50px", 
                        height: "50px",
                        filter: getIconStyle(isActive("/menu")).filter,
                        opacity: getIconStyle(isActive("/menu")).opacity,
                        boxShadow: getIconStyle(isActive("/menu")).boxShadow,
                        transform: getIconStyle(isActive("/menu")).transform,
                    }}
                />
                <span style={{ marginTop: "8px", fontSize: "14px", color: "#333", fontWeight: isActive("/menu") ? "bold" : "normal" }}>
                    Cardápios
                </span>
            </div>

            {/* Ícone Address */}
            <div style={{ 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                padding: isActive("/establishment") ? "5px" : "0",
                background: isActive("/establishment") ? "#e0f7fa" : "transparent",
                borderRadius: "8px",
                transition: "background 0.3s ease", 
            }}>
                <img 
                    src={addressIcon} 
                    alt="Address" 
                    onClick={() => navigate("/establishment")} 
                    style={{
                        cursor: "pointer", 
                        width: "50px", 
                        height: "50px",
                        filter: getIconStyle(isActive("/establishment")).filter,
                        opacity: getIconStyle(isActive("/establishment")).opacity,
                        boxShadow: getIconStyle(isActive("/establishment")).boxShadow,
                        transform: getIconStyle(isActive("/establishment")).transform,
                    }}
                />
                <span style={{ marginTop: "8px", fontSize: "14px", color: "#333", fontWeight: isActive("/establishment") ? "bold" : "normal" }}>
                    Endereços
                </span>
            </div>

            {/* Ícone Design */}
            <div style={{ 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                padding: isActive("/design") ? "5px" : "0",
                background: isActive("/design") ? "#e0f7fa" : "transparent",
                borderRadius: "8px",
                transition: "background 0.3s ease", 
            }}>
                <img 
                    src={designIcon} 
                    alt="Design" 
                    onClick={() => navigate("/design")} 
                    style={{
                        cursor: "pointer", 
                        width: "50px", 
                        height: "50px",
                        filter: getIconStyle(isActive("/design")).filter,
                        opacity: getIconStyle(isActive("/design")).opacity,
                        boxShadow: getIconStyle(isActive("/design")).boxShadow,
                        transform: getIconStyle(isActive("/design")).transform,
                    }}
                />
                <span style={{ marginTop: "8px", fontSize: "14px", color: "#333", fontWeight: isActive("/design") ? "bold" : "normal" }}>
                    Design
                </span>
            </div>
        </div>
    );
};

export default NavigationIcons;
