import React, { useState, useRef } from "react";
import { auth } from "../../firebase/firebaseConfig";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Button } from "primereact/button"; 
import { InputText } from "primereact/inputtext"; 
import { Toast } from "primereact/toast";
import { useNavigate } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Dialog } from "primereact/dialog";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [resetEmail, setResetEmail] = useState("");
    const [displayResetDialog, setDisplayResetDialog] = useState(false);
    const toast = useRef(null);
    const navigate = useNavigate();

    // Itens do breadcrumb
    const items = [
            { label: 'Início', url: '/' },
            { label: 'Login' }
    ];
    
    const home = { icon: 'pi pi-home', url: '/' };

    const handleLogin = async () => {
        if (!email || !password) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Por favor preencha todos os campos.', life: 3000 });
            return;
        }

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            if (!user.emailVerified) {
                toast.current.show({ severity: 'error', summary: 'Falha no login', detail: 'Verifique seu e-mail antes de fazer login.', life: 3000 });
                await auth.signOut(); 
                return;
            }

            toast.current.show({ severity: 'success', summary: 'Login bem-sucedido!', detail: 'Agora você está logado!', life: 3000 });
            navigate('/');
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Falha no login', detail: error.message, life: 3000 });
        }
    };

    const handleResetPassword = async () => {
        if (!resetEmail) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Por favor, insira seu e-mail.', life: 3000 });
            return;
        }

        try {
            await sendPasswordResetEmail(auth, resetEmail);
            toast.current.show({ severity: 'success', summary: 'E-mail enviado', detail: 'Verifique sua caixa de entrada para redefinir a senha.', life: 3000 });
            setDisplayResetDialog(false);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Falha ao enviar e-mail', detail: error.message, life: 3000 });
        }
    };

    return (
        <>
            <BreadCrumb model={items} home={home} />
            <div style={{ maxWidth: '800px', margin: '0 auto'}}>
                <Toast ref={toast} />
                <h2>Acessar a Aplicação</h2>
                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText 
                            id="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            style={{ width: '100%' }} 
                        />
                        <label htmlFor="email">Email</label>
                    </span>
                </div>
                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText 
                            id="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            type="password" 
                            style={{ width: '100%' }} 
                        />
                        <label htmlFor="password">Password</label>
                    </span>
                </div>
                <Button 
                    label="Login" 
                    style={{ marginTop: '1.5rem', marginRight: '10px', width:'100px'  }}
                    onClick={handleLogin} 
                    rounded
                    className="p-mt-2" 
                />
                <Button label="Cancelar" onClick={() => navigate('/')}  severity="secondary" rounded  style={{ marginRight: '10px', width:'100px'}} />
                <Button 
                    label="Esqueci a Senha" 
                    style={{ marginTop: '1.5rem' }}
                    onClick={() => setDisplayResetDialog(true)}
                    className="p-button-text" 
                    rounded
                />

            </div>

            {/* Diálogo para redefinição de senha */}
            <Dialog header="Redefinir Senha" visible={displayResetDialog} onHide={() => setDisplayResetDialog(false)}>
                <div className="p-field">
                    <label htmlFor="resetEmail">Insira seu e-mail para redefinir sua senha:</label>
                    <InputText 
                        id="resetEmail" 
                        value={resetEmail} 
                        onChange={(e) => setResetEmail(e.target.value)} 
                        style={{ width: '100%' }} // Largura total do campo
                    />
                </div>
                <Button label="Enviar e-mail" onClick={handleResetPassword} rounded style={{ marginTop: '1.5rem' }} />
            </Dialog>
        </>
    );
};

export default Login;
