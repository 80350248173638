// src/App.js
import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Header from "./components/Layout/Header"; 
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Content from "./components/Layout/Content";
import Establishment from "./components/Establishment/EstablishmentForm";
import MenuForm from './components/Menu/MenuForm';
import DesignForm from './components/Design/DesignForm';
import { auth } from "./firebase/firebaseConfig"; 
import { onAuthStateChanged, signOut } from "firebase/auth";
import LoadingModal from './components/LoadingModal/LoadingModal'; 
import EmailVerificationMessage from "./components/Auth/EmailVerificationMessage";
import NavigationIcons from "./components/Layout/NavigationIcons";

const App = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                setIsAuthenticated(true);
                setEmailVerified(user.emailVerified);
            } else {
                setUser(null);
                setIsAuthenticated(false);
                setEmailVerified(false);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const handleLogout = async () => {
        setLoading(true); 
        await signOut(auth);
        setIsAuthenticated(false);
        setEmailVerified(false);
        setLoading(false); 
        navigate('/'); 
    };

    return (
        <>
            <Header 
                onLogout={handleLogout} 
                isAuthenticated={isAuthenticated} 
                emailVerified={emailVerified} 
            /> 
            {isAuthenticated && <NavigationIcons />}

            <div style={{ padding: '1rem', opacity: loading ? 0.5 : 1, transition: 'opacity 0.3s ease' }}>
                {loading && <LoadingModal />}
                
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/email-verification-message" element={<EmailVerificationMessage />} /> 
                    <Route path="/menu" element={<MenuForm user={user}/>} />
                    <Route path="/establishment" element={<Establishment user={user} />} />
                    <Route path="/design" element={<DesignForm user={user} />} />
                    <Route path="/" element={<Content />} />
                </Routes>
            </div>
        </>
    );
};

export default App;
