// src/components/Profile/Profile.js
import React from 'react';
import EstablishmentTable from '../EstablishmentTable/EstablishmentTable';
import { BreadCrumb } from 'primereact/breadcrumb';

const EstablishmentForm = ({ user }) => {

    // Itens do breadcrumb
    const items = [
            { label: 'Início', url: '/' },
            { label: 'Endereços' }
         ];
        
    const home = { icon: 'pi pi-home', url: '/' };

    return (
        <div>
            <BreadCrumb model={items} home={home} />
            {user ? (
                <EstablishmentTable user={user} /> 
            ) : (
                <p>Você precisa estar logado para ver seus endereços.</p>
            )}
        </div>
    );
};

export default EstablishmentForm;
