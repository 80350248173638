import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';

const Content = () => {
    // Itens do breadcrumb
    const items = [
      { label: 'Início', url: '/' },
];

const home = { icon: 'pi pi-home', url: '/' };
  return (
    <main>
      <BreadCrumb model={items} home={home} />
      <h1>Bem-vindo ao Carta do Chef</h1>
      <p>Esta é a sua aplicação para gerenciar menus.</p>
      {/* Adicione mais conteúdo aqui */}
    </main>
  );
};

export default Content;
