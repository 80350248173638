import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';  // Certifique-se de importar o BreadCrumb
import MenuTable from '../MenuTable/MenuTable';
import './MenuForm.css';  // Adicione um arquivo CSS se necessário

const MenuForm = ({ user }) => {
    // Itens do breadcrumb
    const items = [
        { label: 'Início', url: '/' },  // Link para a página inicial
        { label: 'Meus Cardápios' }      // Página atual (sem URL, pois já está nela)
    ];

    const home = { icon: 'pi pi-home', url: '/' };  // Ícone da Home

    return (
        <>
            {/* BreadCrumb para navegação */}
            <BreadCrumb model={items} home={home} />

            {/* Conteúdo da página administrativa */}
            <div className="menu-form-container"> {/* Adicione uma classe CSS para estilização */}
                {user ? (
                    <MenuTable user={user} /> 
                ) : (
                    <p>Você precisa estar logado para ver seus estabelecimentos.</p>
                )}
            </div>
        </>
    );
};

export default MenuForm;
