import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { v4 as uuidv4 } from 'uuid';
import { Panel } from 'primereact/panel';
import { useLocation } from 'react-router-dom';
import categoriesData from './util/categories.json';
import { fontOptions, fontSizeOptions, alignOptions } from './util/options';
import { MultiSelect } from 'primereact/multiselect';
import ItemForm from "./ItemForm";
import { doc, setDoc, collection, query, where, getDocs, updateDoc} from 'firebase/firestore';
import { db, storage } from '../../firebase/firebaseConfig';
import { ref, uploadBytes } from 'firebase/storage';
import './css/DesignForm.css';
import './css/DesignTable.css';
import './css/Fonts.css';

const DesignTable = ({ user }) => {
    const location = useLocation();
    const [itemName, setItemName] = useState('');
    const [itemPrice, setItemPrice] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [portionSize, setPortionSize] = useState('');
    const [itemCategory, setItemCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [itemDescription, setItemDescription] = useState('');
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [textAlign, setTextAlign] = useState('center');
    const [userEstablishments, setUserEstablishments] = useState([]);
    const toast = useRef(null); // Crie a referência ao Toast
    const [isActive, setIsActive] = useState(true); // O menu será ativo por padrão
    const [menuName, setMenuName] = useState(''); // Estado para o nome do menu
    const [selectedFontTitle, setSelectedFontTitle] = useState(fontOptions[0].value); // Default para "Arial"
    const [selectedFontSizeTitle, setSelectedFontSizeTitle] = useState(fontSizeOptions[2].value); // Default para "16px"
    const [selectedFontItem, setSelectedFontItem] = useState(fontOptions[0].value); // Default para "Times New Roman"
    const [selectedFontSizePrice, setSelectedFontSizePrice] = useState(fontSizeOptions[2].value); // Default para "18px"
    const [selectedFontPrice, setSelectedFontPrice] = useState(fontOptions[0].value); // Default para "Times New Roman"
    const [selectedFontSizeItem, setSelectedFontSizeItem] = useState(fontSizeOptions[2].value); // Default para "18px"
    const [selectedEstablishments, setSelectedEstablishments] = useState([]); // State for selected establishments
    const [editingItem, setEditingItem] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    
    const getEstablishmentsByUserId = async (userId) => {
        const establishmentsRef = collection(db, "establishments");
        const q = query(establishmentsRef, where("userId", "==", userId));
        const querySnapshot = await getDocs(q);

        const establishments = [];
        querySnapshot.forEach((doc) => {
            establishments.push({ id: doc.id, ...doc.data() });
        });
        return establishments;
    };

    useEffect(() => {
        if (location.state?.item) {
            const { item } = location.state; // Pega o item do state
            setMenuName(item.menuName);
            setSelectedEstablishments(item.establishments.map(e => e.id)); // Mapeando os IDs dos estabelecimentos
            setSelectedFontTitle(item.layout.fontTitle);
            setSelectedFontSizeTitle(item.layout.fontSizeTitle);
            setTextAlign(item.layout.textAlign);
            setSelectedFontItem(item.layout.fontItem);
            setSelectedFontSizeItem(item.layout.fontSizeItem);
            setSelectedFontPrice(item.layout.fontPrice);
            setSelectedFontSizePrice(item.layout.fontSizePrice);
            setItems(item.items); 
            setIsEditing(true); 
        }  else {
            setIsEditing(false); 
        }
    }, [location.state]);

    useEffect(() => {
        setCategories(categoriesData);
    }, []);

    useEffect(() => {
        const fetchUserEstablishments = async () => {
            try {
                const userId = user?.uid;
                if (userId) {
                    const fetchedEstablishments = await getEstablishmentsByUserId(userId);
                    setUserEstablishments(fetchedEstablishments);
                }
            } catch (error) {
                console.error('Erro ao buscar estabelecimentos:', error);
            }
        };

        if (user) {
            fetchUserEstablishments();
        }
    }, [user]);

    const itemTemplate = (option) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <i className={option.icon} style={{ marginRight: '8px' }}></i>
                {option.label}
            </div>
        );
    };


    const handleFontChangeTitle = (e) => {
        setSelectedFontTitle(e.value);
    };

    const handleFontChangeItem = (e) => {
        setSelectedFontItem(e.value);
    };

    const handleFontChangePrice = (e) => {
        setSelectedFontPrice(e.value);
    };

    const handleFontSizeChangeTitle = (e) => {
        setSelectedFontSizeTitle(e.value);
    };

    const handleFontSizeChangeItem = (e) => {
        setSelectedFontSizeItem(e.value);
    };

    const handleFontSizeChangePrice = (e) => {
        setSelectedFontSizePrice(e.value);
    };

    const addItem = () => {
        setLoading(true);

        const fieldsToValidate = { itemName, itemPrice, itemCategory, itemDescription };

        const newErrors = Object.keys(fieldsToValidate).reduce((acc, field) => {
            if (!fieldsToValidate[field]) {
                acc[field] = true;
            }
            return acc;
        }, {});

        if (Object.keys(newErrors).length === 0) {
            const newItem = {
                id: uuidv4(),
                name: itemName,
                price: itemPrice,
                code: itemCode,
                portionSize: portionSize,
                category: itemCategory,
                description: itemDescription,
                isHighlighted,
                fontTitle: selectedFontTitle,
                fontSizeTitle: selectedFontSizeTitle,
                fontItem: selectedFontItem,
                fontSizeItem: selectedFontSizeItem,
                fontPrice: selectedFontPrice,
                fontSizePrice: selectedFontSizePrice,
                textAlign: textAlign,
                userId: user.id,
            };

            setItems([...items, newItem]);

            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Item adicionado com sucesso' });

            // Resetar os campos após adicionar o item
            setItemName('');
            setItemPrice('');
            setItemCode('');
            setPortionSize('');
            setItemCategory('');
            setItemDescription('');
            setErrors({});
            setIsHighlighted(false);
            setLoading(false);
        } else {
            setErrors(newErrors);
            setLoading(false);
        }
    };
    const toggleHighlight = (id) => {
        setItems((prevItems) =>
            prevItems.map(item =>
                item.id === id ? { ...item, isHighlighted: !item.isHighlighted } : item
            )
        );
    };
    const categorizedItems = items.reduce((acc, item) => {
        acc[item.category] = acc[item.category] || [];
        acc[item.category].push(item);
        return acc;
    }, {});
    const formatPrice = (value) => {
        const formattedValue = value.replace(/\D/g, "");
        const price = (Number(formattedValue) / 100).toFixed(2);
        setItemPrice(price);
    };
    const handleDeleteItem = (category, itemId) => {
        setItems((prevItems) => {
            const updatedItems = { ...categorizedItems };
            const itemIndex = updatedItems[category].findIndex(item => item.id === itemId);

            if (itemIndex !== -1) {
                updatedItems[category].splice(itemIndex, 1);
            }

            return Object.values(updatedItems).flat();
        });
    };
    const getFieldClass = (field) => {
        return errors[field] ? 'p-invalid register-input' : 'register-input';
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0'); // Obtém o dia e garante que tenha dois dígitos
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Obtém o mês (0-11) e adiciona 1, garantindo dois dígitos
        const year = date.getFullYear(); // Obtém o ano
    
        return `${day}/${month}/${year}`; // Retorna no formato ddmmaaaa
    };

    const saveData = async () => {
        try {
            setLoading(true); 
            const userId = user ? user.uid : null;
            if (!userId) {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'User ID não está definido.' });
                return;
            }
    
            // Buscando os menus existentes do usuário
            const menusQuery = query(collection(db, "menus"), where("userId", "==", userId));
            const querySnapshot = await getDocs(menusQuery);
            const existingMenusCount = querySnapshot.size; // Conta os menus existentes
    
            // Verifica se o número de menus é menor que 7
            if (!isEditing && existingMenusCount >= 7) {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Só é permitido a criação de 7 cardápios por usuário!' });
                setLoading(false);
                return;
            }
    
            // Validação para garantir que o nome do menu foi preenchido
            if (menuName.trim() === '') {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O nome do cardápio é obrigatório.' });
                setLoading(false);
                return;
            }
    
            if (items.length < 3) {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'É necessário ter pelo menos 3 itens para salvar o cardápio!' });
                setLoading(false);
                return;
            }
    
            if (selectedEstablishments.length === 0) {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'É necessário selecionar ao menos um estabelecimento.' });
                setLoading(false);
                return;
            }
    
            // Verifica se é edição ou novo cadastro
            const menuId = location.state?.item?.id || uuidv4(); // Usa o ID existente ou gera um novo ID
    
            // Mapeia os itens adicionando o userId
            const itemsToSave = items.map(item => ({
                ...item,
                userId: userId, // Adiciona o userId a cada item
            }));
    
            // Filtra as informações completas dos estabelecimentos selecionados
            const selectedEstablishmentsData = userEstablishments.filter(establishment => selectedEstablishments.includes(establishment.id));
    
            // Cria uma referência para o documento usando o menuId
            const docRef = doc(db, "menus", menuId);
    
            // Define os dados do documento
            const menuData = {
                id: menuId,
                userId: userId,
                menuName: menuName,
                items: itemsToSave,
                isActive: isActive, 
                establishments: selectedEstablishmentsData,
                layout: {
                    fontTitle: selectedFontTitle || 'defaultFontTitle',
                    fontSizeTitle: selectedFontSizeTitle || 'defaultSize',
                    fontItem: selectedFontItem || 'defaultFontItem',
                    fontSizeItem: selectedFontSizeItem || 'defaultSize',
                    fontPrice: selectedFontPrice || 'defaultFontPrice',
                    fontSizePrice: selectedFontSizePrice || 'defaultSize',
                    textAlign: textAlign || 'left',
                },
                // Defina createdAt apenas se for um novo cardápio
                ...(location.state?.item ? {} : { createdAt: formatDate(new Date()) }), // Define apenas ao criar
                updatedAt: formatDate(new Date()), // Sempre atualiza
            };
    
            // Verifica se é edição ou criação
            if (location.state?.item) {
                // Atualiza o cardápio existente
                await updateDoc(docRef, menuData);
                toast.current.show({
                    severity: 'success',
                    summary: 'Sucesso',
                    detail: (
                        <>
                            Cardápio atualizado com sucesso!{' '}
                            <a href="/menu" style={{ color: '#007bff', textDecoration: 'underline' }}>Visualizar menu</a>
                        </>
                    ),
                    life: 10000, 
                });
            // Cria uma pasta no Storage com o menuId
        
            const storageRef = ref(storage, `menus/${menuId}`);
            
            // (Opcional) Carrega um arquivo de exemplo na pasta
            const placeholderFile = new Blob(["Conteúdo de exemplo"], { type: "text/plain" });
            await uploadBytes(ref(storageRef, 'example.txt'), placeholderFile);

            } else {
                // Cria um novo cardápio
                await setDoc(docRef, menuData);
                toast.current.show({
                    severity: 'success',
                    summary: 'Sucesso',
                    detail: (
                        <>
                            Cardápio salvo com sucesso!{' '}
                            <a href="/menu" style={{ color: '#007bff', textDecoration: 'underline' }}>Visualizar menu</a>
                        </>
                    ),
                    life: 10000, 
                });
            // Cria uma pasta no Storage com o menuId
            const storage = storage();
            const storageRef = ref(storage, `menus/${menuId}`);
            
            // (Opcional) Carrega um arquivo de exemplo na pasta
            const placeholderFile = new Blob(["Conteúdo de exemplo"], { type: "text/plain" });
            await uploadBytes(ref(storageRef, 'example.txt'), placeholderFile);


            }
    
            // Limpa os estados
            setItems([]);
            setSelectedEstablishments([]);
            setMenuName('');
    
            setLoading(false);
        } catch (error) {
            console.error('Erro ao salvar o cardápio:', error); // Log do erro no console
            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Erro ao salvar o cardápio na base!' });
            setLoading(false);
        }
    };

    return (
        <div className="login-container page-content">
            <Toast ref={toast} /> {/* Componente Toast */}
            <Panel header="Status do Cardápio">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {/* Campo de texto para o nome do menu */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <label htmlFor="menu-name">Nome do Cardápio:</label>
                <input 
                    id="menu-name" 
                    type="text" 
                    value={menuName} 
                    onChange={(e) => setMenuName(e.target.value)} 
                    placeholder="Digite um nome para o cardápio"
                    required // Torna o campo obrigatório
                    style={{ padding: '5px', width: '100%' }} 
                />
            </div>

            {/* Checkbox para definir o status do cardápio público ou não */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <label htmlFor="menu-status">Cardápio Público?</label>
                <input 
                    id="menu-status" 
                    type="checkbox" 
                    checked={isActive} 
                    onChange={(e) => setIsActive(e.target.checked)} 
                />
            </div>
        </div>
        </Panel>
            <div style={{ position: 'fixed', bottom: '20px', right: '20px', display: 'flex', alignItems: 'center', zIndex: 1000 }}>
                <Button
                    icon="pi pi-save"
                    className="floating-button p-button-success fading rotate"
                    onClick={saveData}
                    style={{ marginRight: '55px' }}
                />
                <Button
                    icon="pi pi-plus"
                    className="floating-button fading rotate"
                    onClick={() => setModalVisible(true)}
                />
            </div>
            <Dialog
                header="Adicionar Item"
                visible={modalVisible}
                className="custom-dialog"
                onHide={() => setModalVisible(false)}
            >
                <ItemForm
                    itemName={itemName}
                    setItemName={setItemName}
                    itemCode={itemCode}
                    setItemCode={setItemCode}
                    itemPrice={itemPrice}
                    setItemPrice={setItemPrice}
                    formatPrice={formatPrice}
                    portionSize={portionSize}
                    setPortionSize={setPortionSize}
                    itemCategory={itemCategory}
                    setItemCategory={setItemCategory}
                    itemDescription={itemDescription}
                    setItemDescription={setItemDescription}
                    addItem={addItem}
                    loading={loading}
                    errors={errors}
                    categories={categories}
                    getFieldClass={getFieldClass}
                    onCancel={() => setModalVisible(false)}
                />
            </Dialog>

            {/* Seção Design */}

            <TabView>
                <TabPanel header=" Endereços" leftIcon="pi pi-map mr-2">
                    <Panel header="Escolha os estabelecimentos para incluir no cardápio!">
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <MultiSelect
                                value={selectedEstablishments}
                                options={userEstablishments.map(e => ({ label: e.establishmentName, value: e.id }))}
                                onChange={(e) => setSelectedEstablishments(e.value)}
                                placeholder="Selecione os estabelecimentos"
                                display="chip" // Optional: to display selected items as chips
                                style={{ width: '100%', maxWidth: '400px' }} // Optional: makes the MultiSelect full width
                            />
                        </div>
                    </Panel>
                </TabPanel>
                <TabPanel header=" Título" leftIcon="pi pi-tag mr-2" className="custom-tab">
                    <Panel header="Customize o título do cardápio!">
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <Dropdown
                                value={selectedFontTitle}
                                options={fontOptions}
                                onChange={handleFontChangeTitle}
                                style={{ width: '100%', maxWidth: '400px' }}
                            />
                            <Dropdown
                                value={selectedFontSizeTitle}
                                options={fontSizeOptions}
                                onChange={handleFontSizeChangeTitle}
                                style={{ width: '100%', maxWidth: '400px' }}
                            />
                            <Dropdown
                                value={textAlign}
                                options={alignOptions}
                                onChange={(e) => setTextAlign(e.value)}
                                itemTemplate={itemTemplate}
                                style={{ width: '100%', maxWidth: '400px' }}
                            />
                        </div>
                    </Panel>
                </TabPanel>
                <TabPanel header=" Texto" leftIcon="pi pi-book mr-2" >
                    <Panel header="Customize o texto do cardápio!">
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <Dropdown
                                value={selectedFontItem}
                                options={fontOptions}
                                onChange={handleFontChangeItem}
                                style={{ width: '100%', maxWidth: '400px' }}
                            />
                            <Dropdown
                                value={selectedFontSizeItem}
                                options={fontSizeOptions}
                                onChange={handleFontSizeChangeItem}
                                style={{ width: '100%', maxWidth: '400px' }}
                            />
                        </div>
                    </Panel>
                </TabPanel>
                <TabPanel header=" Preço" leftIcon="pi pi-dollar mr-2" className="custom-tab">
                    <Panel header="Customize o preço do cardápio!">
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <Dropdown
                                value={selectedFontPrice}
                                options={fontOptions}
                                onChange={handleFontChangePrice}
                                style={{ width: '100%', maxWidth: '400px' }}
                            />
                            <Dropdown
                                value={selectedFontSizePrice}
                                options={fontSizeOptions}
                                onChange={handleFontSizeChangePrice}
                                style={{ width: '100%', maxWidth: '400px' }}
                            />
                        </div>
                    </Panel>
                </TabPanel>
            </TabView>


            {/* Listagem dos Itens Adicionados */}
            <div className="menu-items-section">
                <div className="added-items-container">
                    {Object.keys(categorizedItems).map((category) => (
                        <div className="category-card" key={category}>
                            <h4 className="category-title" style={{ fontFamily: selectedFontTitle, fontSize: selectedFontSizeTitle, textAlign: textAlign }}>
                                {category}
                            </h4>
                            <ul className="item-list">
                                {categorizedItems[category].map((item) => (
                                    <li
                                        className="item"
                                        key={item.id}
                                        style={{
                                            fontFamily: selectedFontItem,
                                            fontSize: selectedFontSizeItem,
                                            backgroundColor: item.isHighlighted ? '#ffeb3b' : '#fff',
                                            position: 'relative', // Isso é opcional, mas pode ajudar a manter a estrutura
                                            zIndex: 1, // Abaixo dos botões flutuantes
                                        }}
                                    >
                                        <div className="item-details">
                                            <span className="item-price">{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</span>
                                            <span style={{ flexGrow: 1, borderBottom: '2px dotted #888', margin: '0 10px' }}></span>
                                            <span className="item-price" style={{ fontFamily: selectedFontPrice, fontSize: selectedFontSizePrice }}>R$ {item.price}</span>
                                        </div>
                                        <div className="item-description">{item.description}</div>
                                        <div className="item-info">
                                            {item.code && <><b>Código:</b> {item.code}</>}
                                            {item.code && item.portionSize && " | "}
                                            {item.portionSize && <><b>Tamanho da Porção:</b> {item.portionSize}</>}
                                        </div>
                                        <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'flex-end' }}>
                                            <Button
                                                icon="pi pi-bell"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleHighlight(item.id);
                                                }}
                                                rounded
                                                className="p-button-rounded p-button-warning"
                                                aria-label="Bookmark"
                                            />
                                            <Button
                                                icon="pi pi-trash"
                                                className="p-button-rounded p-button-danger"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleDeleteItem(category, item.id);
                                                }}
                                                rounded
                                                aria-label="Deletar"
                                            />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DesignTable;
