import React, { useState, useRef } from "react";
import { auth } from "../../firebase/firebaseConfig"; 
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth"; 
import { Button } from "primereact/button"; 
import { InputText } from "primereact/inputtext"; 
import { Toast } from "primereact/toast";
import { useNavigate } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';

const Register = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const toast = useRef(null); 
    const navigate = useNavigate();

    // Itens do breadcrumb
    const items = [
            { label: 'Início', url: '/' },
            { label: 'Registrar' }
         ];
        
    const home = { icon: 'pi pi-home', url: '/' };

    const handleRegister = async () => {
        if (!email || !password) {
            toast.current.show({ severity: 'warn', summary: 'Aviso', detail: 'Por favor preencha todos os campos.', life: 3000 });
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Enviar e-mail de verificação
            await sendEmailVerification(user); 
            await signOut(auth); 

            toast.current.show({ severity: 'success', summary: 'Registro realizado com sucesso!', detail: 'Verifique seu e-mail antes de realizar o login.', life: 3000 });

            navigate('/email-verification-message');
        } catch (error) {
            // Melhorar a mensagem de erro
            let errorMessage = "Ocorreu um erro. Tente novamente.";
            if (error.code === 'auth/email-already-in-use') {
                errorMessage = "Este e-mail já está em uso.";
            }
            toast.current.show({ severity: 'error', summary: 'Registro falhou!', detail: errorMessage, life: 3000 });
        }
    };

    return (
        <>
            <BreadCrumb model={items} home={home} />
            <div style={{ maxWidth: '800px', margin: '0 auto' }}>
                <Toast ref={toast} />
                <h2>Criar uma Conta</h2>
                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText 
                            id="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            style={{ width: '100%' }} 
                        />
                        <label htmlFor="email">Email</label>
                    </span>
                </div>
                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText 
                            id="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            type="password" 
                            style={{ width: '100%' }} 
                        />
                        <label htmlFor="password">Senha</label>
                    </span>
                </div>
                <Button 
                    label="Registrar" 
                    style={{ marginTop: '1.5rem', marginRight: '10px' }}
                    onClick={handleRegister} 
                    rounded
                    className="p-mt-2" 
                />
                 <Button label="Cancelar" severity="secondary" rounded  onClick={() => navigate('/')}  style={{ marginRight: '10px' }} />
            </div>
        </>
    );
};

export default Register;
