// options.js
export const fontOptions = [
    { label: 'Arial', value: 'Arial, sans-serif' },
    { label: 'Times New Roman', value: 'Times New Roman, serif' },
    { label: 'Courier New', value: 'Courier New, monospace' },
    { label: 'Georgia', value: 'Georgia, serif' },
    { label: 'Verdana', value: 'Verdana, sans-serif' },
    { label: 'Metal Lord', value: "'Metal Lord', cursive" },
    { label: 'UnifrakturMaguntia', value: "'UnifrakturMaguntia', cursive" },
    { label: 'Cloister Black', value: "'Cloister Black', serif" },
    { label: 'Iron Maiden', value: "'Iron Maiden', sans-serif" },
];

export const fontSizeOptions = [
    { label: '12px', value: '12px' },
    { label: '14px', value: '14px' },
    { label: '16px', value: '16px' },
    { label: '18px', value: '18px' },
    { label: '20px', value: '20px' },
    { label: '22px', value: '22px' },
    { label: '24px', value: '24px' },
    { label: '26px', value: '26px' },
    { label: '28px', value: '28px' },
    { label: '30px', value: '30px' },
    { label: '32px', value: '32px' },
];

export const alignOptions = [
    { label: 'Esquerda', value: 'left', icon: 'pi pi-align-left' },
    { label: 'Centro', value: 'center', icon: 'pi pi-align-center' },
    { label: 'Direita', value: 'right', icon: 'pi pi-align-right' },
];
