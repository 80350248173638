import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useNavigate } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { Dialog } from 'primereact/dialog';
import imageList from '../../images/iconList.png';
import { collection, onSnapshot, query, orderBy, deleteDoc, updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase/firebaseConfig';
import './MenuTable.css';

const MenuTable = ({ user }) => {
    const [menus, setMenus] = useState([]);
    const toast = useRef(null);
    const navigate = useNavigate();
    const [qrVisible, setQrVisible] = useState(false);
    const [qrData, setQrData] = useState('');

    useEffect(() => {
        const fetchMenus = async () => {
            const userId = user ? user.uid : null;
            if (userId) {
                const q = query(
                    collection(db, "menus"),
                    orderBy("updatedAt", "asc")
                );

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const data = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setMenus(data);
                });

                return () => unsubscribe();
            } else {
                setMenus([]);
            }
        };
        fetchMenus();
    }, [user]);


    const deleteMenu = async (id) => {
        try {
            await deleteDoc(doc(db, "menus", id));
            setMenus(prevMenus => prevMenus.filter(menu => menu.id !== id));
            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Menu deletado com sucesso!' });
        } catch (error) {
            console.error("Erro ao deletar menu:", error);
            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Erro ao deletar menu.' });
        }
    };

    const toggleActiveStatus = async (menu) => {
        try {
            const updatedStatus = !menu.isActive; // Inverte o status
            const docRef = doc(db, "menus", menu.id);

            await updateDoc(docRef, { isActive: updatedStatus }); // Atualiza o documento no Firestore

            // Atualiza o estado local dos menus
            setMenus(prevMenus =>
                prevMenus.map(m => (m.id === menu.id ? { ...m, isActive: updatedStatus } : m))
            );

            // Função para exibir o toast com cor personalizada
            const showToast = (isActive) => {
                const message = `Cardápio ${isActive ? 'publicado' : 'despublicado'} com sucesso!`;

                // Define a severidade e a classe com base no status
                const severity = isActive ? 'success' : 'warn'; // 'info' para despublicado
                const customClass = isActive ? 'custom-toast-success' : 'custom-toast-warn'; // Classe CSS personalizada

                // Exibe o toast
                toast.current.show({
                    severity: severity,
                    summary: 'Sucesso',
                    detail: message,
                    className: customClass // Adiciona a classe personalizada
                });
            };

            // Chamada da função showToast com o status atualizado
            showToast(updatedStatus);
        } catch (error) {
            console.error("Erro ao atualizar status:", error);
            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Erro ao atualizar status.' });
        }
    };

    const showQRCode = (rowData) => {
        const qrLink = `https://seusite.com/menu/${rowData.id}`;
        setQrData(qrLink);
        setQrVisible(true);
    };

    return (
        <div className="menu-table">
            <Toast ref={toast} />
            <Button
                className="floating-button fading rotate"
                tooltipOptions={{ position: 'left' }}
                icon="pi pi-plus"
                onClick={() => navigate('/design')}
            />

            <div className="menu-grid-container">
                {menus.map(rowData => (
                    <div key={rowData.id} className={`menu-card ${!rowData.isActive ? 'inactive' : ''}`}>
                        <div className="menu-card-content">
                            <div className="menu-name">{rowData.menuName}</div>
                            <div className="menu-date">Atualizado em: {rowData.createdAt}</div>
                            <img src={imageList} className="menu-image" alt={rowData.menuName} />
                        </div>
                        <div className="button-group">
                            <Button
                                icon="pi pi-qrcode"
                                label="QRCode"
                                className="p-button-rounded p-button-info"
                                onClick={() => showQRCode(rowData)}
                            />
                            <Button
                                icon={rowData.isActive ? "pi pi-eye-slash" : "pi pi-eye"}
                                label={rowData.isActive ? "Ocultar" : "Exibir"}
                                className={`p-button-rounded ${rowData.isActive ? "p-button-secondary" : "exibir-button"}`}
                                onClick={() => toggleActiveStatus(rowData)}
                            />
                            <Button
                                icon="pi pi-pencil"
                                label="Editar"
                                className="p-button-rounded p-button-warning"
                                onClick={() => navigate('/design', { state: { item: rowData } })}
                            />
                            <Button
                                icon="pi pi-trash"
                                label="Excluir"
                                className="p-button-rounded p-button-danger"
                                onClick={() => deleteMenu(rowData.id)}
                            />
                        </div>
                    </div>
                ))}
            </div>



            <Dialog header="QR Code do Cardápio" visible={qrVisible} onHide={() => setQrVisible(false)}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <QRCodeCanvas value={qrData} size={200} />
                </div>
            </Dialog>
        </div>
    );
};

export default MenuTable;
