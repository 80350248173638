// src/components/EstablishmentTable/EstablishmentTable.js
import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import local from '../../images/local.png';
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { collection, onSnapshot, doc, deleteDoc, updateDoc, addDoc, query, where } from "firebase/firestore";
import { db } from '../../firebase/firebaseConfig';
import './EstablishmentForm.css';

const EstablishmentTable = ({ user }) => {

    const [establishments, setEstablishments] = useState([]);
    const [selectedEstablishment, setSelectedEstablishment] = useState(null);
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [errors, setErrors] = useState({});
    const toast = useRef(null);

    useEffect(() => {
        const fetchEstablishments = async () => {
            const userId = user ? user.uid : null;
            if (userId) {
                const q = query(collection(db, "establishments"), where("userId", "==", userId));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const data = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setEstablishments(data);
                });

                return () => unsubscribe();
            }
        };

        fetchEstablishments();
    }, [user]);

    const formatDate = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            const date = new Date(timestamp.seconds * 1000); // Converte segundos para milissegundos
            return date.toLocaleDateString(); // Formata a data no formato legível
        }
        return "";
    };

    const getFieldClass = (field) => {
        return errors[field] ? 'p-invalid register-input' : 'register-input';
    };

    const openNewDialog = () => {
        if (establishments.length >= 5) {
            toast.current.show({ severity: 'warn', summary: 'Limite Atingido', detail: 'Você só pode cadastrar até 5 endereços.' });
            return;
        }
        setSelectedEstablishment({
            establishmentName: "",
            phone: "",
            whatsapp: "",
            website: "",
            facebook: "",
            instagram: "",
            zipCode: "",
            publicPlace: "",
            neighborhood: "",
            city: "",
            state: "",
            number: "",
            complement: "",
            createdAt: new Date(),
            updatedAt: new Date(),
            userId: user ? user.uid : null,
        });
        setIsNew(true);
        setIsDialogVisible(true);
    };

    const openEditDialog = (establishment) => {
        setSelectedEstablishment(establishment);
        setIsNew(false);
        setIsDialogVisible(true);
    };

    const hideDialog = () => {
        setIsDialogVisible(false);
        setSelectedEstablishment(null);
        setErrors({});
    };

    const validateFields = () => {
        const requiredFields = ["establishmentName", "zipCode", "number", "phone"];
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!selectedEstablishment[field]) {
                newErrors[field] = true;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const saveEstablishment = async () => {
        if (!validateFields()) {
            return;
        }

        const currentDate = new Date();

        if (isNew) {
            // Adicionar novo estabelecimento
            try {
                const docRef = await addDoc(collection(db, "establishments"), {
                    ...selectedEstablishment,
                    createdAt: currentDate,
                    updatedAt: currentDate,
                });
                setEstablishments([...establishments, { ...selectedEstablishment, id: docRef.id }]);
            } catch (error) {
                console.error("Erro ao adicionar o endereço:", error);
            }
        } else {
            // Editar estabelecimento existente
            try {
                const docRef = doc(db, "establishments", selectedEstablishment.id);
                await updateDoc(docRef, {
                    ...selectedEstablishment,
                    updatedAt: currentDate,
                });
                setEstablishments((prevEstablishments) =>
                    prevEstablishments.map((establishment) =>
                        establishment.id === selectedEstablishment.id
                            ? { ...selectedEstablishment, updatedAt: currentDate }
                            : establishment
                    )
                );
            } catch (error) {
                console.error("Erro ao atualizar endereço:", error);
            }
        }
        hideDialog();
    };

    const deleteEstablishment = async (id) => {
        try {
            await deleteDoc(doc(db, "establishments", id));
            setEstablishments(establishments.filter(establishment => establishment.id !== id));
        } catch (error) {
            console.error("Erro ao deletar o endereço:", error);
        }
    };

    const fetchAddress = async (zipCode) => {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`);
            const data = await response.json();
            if (!data.erro) {
                setSelectedEstablishment(prevState => ({
                    ...prevState,
                    publicPlace: data.logradouro,
                    neighborhood: data.bairro,
                    city: data.localidade,
                    state: data.uf,
                }));
            }
        } catch (error) {
            console.error("Erro ao buscar endereço:", error);
        }
    };

    return (
        <div className="menu-grid-container">
            <Toast ref={toast} />
                <Button className="floating-button fading rotate" tooltipOptions={{ position: 'left' }} icon="pi pi-plus" onClick={openNewDialog} />
                {establishments.map(rowData => (
                    <div key={rowData.id} className="menu-card"> {/* Certifique-se de que esta classe esteja aqui */}
                       
                        <div className="menu-name">{rowData.establishmentName}</div>
                        <div className="menu-date">Última atualização: {formatDate(rowData.updatedAt)}</div>
                        <div className="menu-date">CEP: {rowData.zipCode}</div>
                        <img src={local} className="menu-image" alt="Local" />
                        <div className="button-group">
                            <Button icon="pi pi-pencil" label="Editar" className="p-button-rounded p-button-warning" onClick={() => openEditDialog(rowData)} />
                            <Button icon="pi pi-trash" label="Excluir" className="p-button-rounded p-button-danger" onClick={() => deleteEstablishment(rowData.id)} severity="danger" />
                        </div>
                    </div>
                ))}
        

            <Dialog visible={isDialogVisible} onHide={hideDialog} header={isNew ? 'Novo Estabelecimento' : 'Editar Estabelecimento'} className="custom-dialog">
                <div className="p-field" style={{ marginTop: '0.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="establishmentName"
                            className={getFieldClass('establishmentName')}
                            value={selectedEstablishment?.establishmentName || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, establishmentName: e.target.value })}
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="establishmentName">Nome do Estabelecimento </label>
                    </span>
                </div>

                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="phone"
                            className={getFieldClass('phone')}
                            value={selectedEstablishment?.phone || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, phone: e.target.value })}
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="phone">Telefone</label>
                    </span>
                </div>

                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="facebook"
                            value={selectedEstablishment?.facebook || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, facebook: e.target.value })}
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="facebook">Facebook</label>
                    </span>
                </div>

                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="instagram"
                            value={selectedEstablishment?.instagram || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, instagram: e.target.value })}
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="instagram">Instagram</label>
                    </span>
                </div>

                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="website"
                            value={selectedEstablishment?.website || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, website: e.target.value })}
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="website">Website</label>
                    </span>
                </div>

                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="whatsapp"
                            className={getFieldClass('whatsapp')}
                            value={selectedEstablishment?.whatsapp || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, whatsapp: e.target.value })}
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="whatsapp">WhatsApp</label>
                    </span>
                </div>

                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="zipCode"
                            className={getFieldClass('zipCode')}
                            value={selectedEstablishment?.zipCode || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, zipCode: e.target.value })}
                            onBlur={() => fetchAddress(selectedEstablishment?.zipCode)}
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="zipCode">CEP</label>
                    </span>
                </div>

                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="publicPlace"
                            value={selectedEstablishment?.publicPlace || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, publicPlace: e.target.value })}
                            style={{ width: '100%' }}
                            disabled
                        />
                        <label htmlFor="publicPlace">Logradouro</label>
                    </span>
                </div>

                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="neighborhood"
                            value={selectedEstablishment?.neighborhood || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, neighborhood: e.target.value })}
                            style={{ width: '100%' }}
                            disabled
                        />
                        <label htmlFor="neighborhood">Bairro</label>
                    </span>
                </div>

                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="city"
                            value={selectedEstablishment?.city || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, city: e.target.value })}
                            style={{ width: '100%' }}
                            disabled
                        />
                        <label htmlFor="city">Cidade</label>
                    </span>
                </div>

                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="state"
                            value={selectedEstablishment?.state || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, state: e.target.value })}
                            style={{ width: '100%' }}
                            disabled
                        />
                        <label htmlFor="state">Estado</label>
                    </span>
                </div>

                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="number"
                            className={getFieldClass('number')}
                            value={selectedEstablishment?.number || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, number: e.target.value })}
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="number">Número</label>
                    </span>
                </div>

                <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText
                            id="complement"
                            value={selectedEstablishment?.complement || ''}
                            onChange={(e) => setSelectedEstablishment({ ...selectedEstablishment, complement: e.target.value })}
                            style={{ width: '100%' }}
                        />
                        <label htmlFor="complement">Complemento</label>
                    </span>
                </div>

                <Button label="Salvar" onClick={saveEstablishment} rounded style={{ marginTop: '0.5rem', marginRight: '10px', width: '100px' }} />
                <Button label="Cancelar" onClick={hideDialog} severity="secondary" rounded style={{ marginTop: '0.5rem', marginRight: '10px', width: '100px' }} />
            </Dialog>
        </div>
    );
};

export default EstablishmentTable;
