// src/firebase/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Inicializa o Firebase
let app;
try {
  app = initializeApp(firebaseConfig);
} catch (error) {
  console.error("Erro ao inicializar o Firebase:", error);
}

// Inicializa os serviços do Firebase
export const auth = getAuth(app); // Serviço de autenticação
export const db = getFirestore(app); // Firestore
export const storage = getStorage(app); // Storage
export const analytics = getAnalytics(app); // Google Analytics
