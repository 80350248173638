import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

const ItemForm = ({
    itemName, setItemName, itemCode, setItemCode, itemPrice, onCancel ,
    formatPrice, portionSize, setPortionSize, itemCategory, setItemCategory,
    itemDescription, setItemDescription, addItem, categories, getFieldClass
}) => {
    return (
        <div>
            <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText 
                            name="name"
                            value={itemName}
                            onChange={(e) => setItemName(e.target.value)}
                            className={getFieldClass('itemName')}
                            maxLength={50} 
                        />
                        <label htmlFor="itemName">Nome do Item</label>
                    </span>
            </div>
            <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText 
                            name="code"
                            value={itemCode}
                            onChange={(e) => setItemCode(e.target.value)}
                            placeholder="Código"
                            className={getFieldClass('itemCode')}
                            maxLength={10}
                        />
                        <label htmlFor="itemCode">Código</label>
                    </span>
            </div>
            <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText 
                            name="price"
                            value={itemPrice}
                            onChange={(e) => formatPrice(e.target.value)}
                            className={getFieldClass('itemPrice')}
                        />
                        <label htmlFor="itemPrice">Preço</label>
                    </span>
            </div>      
            <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText 
                            name="portionSize"
                            value={portionSize}
                            onChange={(e) => setPortionSize(e.target.value)}
                            className={getFieldClass('portionSize')}
                        />
                        <label htmlFor="portionSize">Tamanho da Porção (ex: 250g)</label>
                    </span>
            </div>     
            <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
            <Dropdown
                value={itemCategory}
                options={categories.length > 0 ? categories : []} // Garante opções válidas
                onChange={(e) => setItemCategory(e.value)}
                placeholder="Selecione uma Categoria"
                style={{ width: '100%', marginBottom: '0.5rem' }}
                filter
                className={getFieldClass('itemCategory')}
            />
            </div>  
            <div className="p-field" style={{ marginTop: '1.5rem', width: '100%' }}>
                    <span className="p-float-label" style={{ width: '100%' }}>
                        <InputText 
                            name="description"
                            value={itemDescription}
                            onChange={(e) => setItemDescription(e.target.value)}
                            className={getFieldClass('itemDescription')}
                        />
                        <label htmlFor="itemDescription">Descrição</label>
                    </span>
            </div>  
            <Button 
                label="Salvar" 
                style={{ marginTop: '1.5rem', marginRight: '10px', width:'100px' }}
                onClick={addItem} 
                rounded
                className="p-mt-2" 
            />
            <Button 
                label="Cancelar"  
                onClick={onCancel} 
                severity="secondary" 
                rounded
                style={{ marginRight: '10px', width:'100px' }} 
            />
        </div>
    );
};

export default ItemForm;
