import React from "react";
import { Card } from "primereact/card";
import './Banner.css'; // Importe o arquivo CSS

const Banner = () => {
    return (
        <Card className="banner"> {/* Aplique a classe ao Card */}
        </Card>
    );
};

export default Banner;
