import React from "react";
import ReactDOM from "react-dom/client"; // Atualização para React 18
import App from "./App";
import 'primereact/resources/themes/nano/theme.css'; // Tema do PrimeReact
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { BrowserRouter as Router } from 'react-router-dom'; // Importar Router aqui

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Router> {/* Router aqui para envolver o App */}
      <App />
    </Router>
  </React.StrictMode>
);
