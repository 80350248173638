// src/components/Profile/Profile.js
import React from 'react';
import DesignTable from '../DesignTable/DesignTable';
import { BreadCrumb } from 'primereact/breadcrumb';

const DesignForm = ({ user }) => {

    // Itens do breadcrumb
    const items = [
            { label: 'Início', url: '/' },
            { label: 'Cardápios' }
         ];
        
    const home = { icon: 'pi pi-home', url: '/' };

    return (
        <div>
            <BreadCrumb model={items} home={home} />
            {user ? (
                <DesignTable user={user} /> 
            ) : (
                <p>Você precisa estar logado para ver seus cardápios.</p>
            )}
        </div>
    );
};

export default DesignForm;
